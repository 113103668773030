var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mask-cont"
  }, [_c('img', {
    staticClass: "light-style",
    attrs: {
      "src": require("./images/light.png"),
      "alt": "",
      "width": "600",
      "height": "600"
    }
  }), _c('div', {
    staticClass: "coin-style"
  }, [_vm._v(" " + _vm._s(_vm.pointsNum) + " ")]), _c('img', {
    staticClass: "start-style1",
    attrs: {
      "src": require("./images/start.png"),
      "alt": "",
      "width": "40",
      "height": "40"
    }
  }), _c('img', {
    staticClass: "start-style2",
    attrs: {
      "src": require("./images/start.png"),
      "alt": "",
      "width": "30",
      "height": "30"
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };