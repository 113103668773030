var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "active-info"
  }, [_c('span', [_vm._v("本月统计：参与活动次数 " + _vm._s(_vm.detailData.activityCount || 0))]), _c('span', {
    staticStyle: {
      "cursor": "pointer!important"
    },
    on: {
      "click": function click($event) {
        return _vm.gomyaccount();
      }
    }
  }, [_vm._v("账户金额 ¥" + _vm._s(_vm.formatNum(_vm.detailData.totalBalanceAmount)))]), _vm.detailData.stat == 1 ? _c('span', [_vm._v("返点值 " + _vm._s(_vm.detailData.rebate || 0))]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };