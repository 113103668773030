<template>
  <div class="product-list-template-box">
    <!-- 栏目标题 -->
    <div
      :class="['class-title-box', 'title-' + columnType]"
      v-if="columnType && isShowTitle"
    >
      <div>
        <img
          src="@/assets/images/pic_hot.png"
          alt
          v-if="columnType === 'hot'"
        />
        <img
          src="@/assets/images/pic_like.png"
          alt
          v-if="columnType === 'like'"
        />
        <img
          src="@/assets/images/pic_Imported.png"
          alt
          v-if="columnType === 'imported'"
        />
        <img
          src="@/assets/images/pic_new.png"
          alt
          v-if="columnType === 'new'"
        />
        <div
          v-if="columnType !== 'hot'"
          class="change-title"
          @click="toChangeList"
        >
          <span class="change-span">换一批</span>
          <img src="@/assets/images/change_icon.svg" alt />
        </div>
      </div>
      <p v-if="columnType !== 'hot'" @click="toLookMore">
        更多
        <i class="fa fa-caret-right" aria-hidden="true"></i>
      </p>
    </div>
    <!-- 商品数据 -->
    <div class="product-list-box">
      <div
        v-for="(item, index) in productList"
        :key="index"
        class="product-box"
        style="cursor: pointer"
      >
        <!--2021-11-15 修改 hy-->
        <b-tooltip :position="item.isShowModel ? 'is-left' : 'is-right'" multilined type="is-light">
          <div class="product-top-box" @click="toDetail(item)">
                  <div class="product-img-box">
                      <div class="product-img" :style="{backgroundImage:'url('+item.mainImageUrl+')'}">
                          <!-- <img :src="item.mainImageUrl" alt /> -->
                          <div class="product-hbimg-jf" v-if="item.productAttribute=='1'">
                              返利
                          </div>
                          <div class="product-hbimg" v-else-if="item.activityVOList[0]&&item.activityVOList[0].isIntegralCommodity&&item.activityVOList[0].isIntegralCommodity=='1'">
                              <img src="@images/2.gif">
                          </div>
                          <div class="hospital" v-if="item.hospitalClassificationValue && item.hospitalClassificationValue == '1'">
                              医院
                          </div>
                          <img v-if="item.productPatent && item.productPatent == '1'" class="db_icon" src="https://obs.pujian.com/frontend/app/components/db_icon.png" alt />
                          <!--零差价标识-->
                          <div class="zero-price-img" v-if="item.isZeroPriceDifference == 1">
                              <img src="@images/PC0.png">
                          </div>
                      </div>
                      <!-- 今日爆款显示的排行榜 -->
                      <div
                              v-if="isUserPoints === 1 && item.topType"
                              class="product-top-left-tag product-ranking"
                      >
                          TOP {{ item.topType }}
                      </div>
                      <div
                              class="product-ranking left-box"
                              v-if="item.buycs&&item.buycs>0"
                      >
                          买过 {{ item.buycs }} 次
                      </div>
                      <div
                              class="right-box"
                              v-if="item.orderTime && !item.product_buy_num_total"
                      >
                          {{ item.orderTime | timeFilter }}
                      </div>
                      <!-- <div class="right-box" v-if="item.saleNum">
                        已销{{ item.saleNum }}{{ item.sellUnit }}
                      </div> -->
                      <!-- <div class="boottom-box" v-if="item.product_buy_num">买过{{item.product_buy_num}}次</div> -->
                      <!-- 积分阅读，加积分 -->
                      <b-button
                              @click.stop="toAddIntegral(item)"
                              class="product-top-left-tag product-sales-integral"
                              :disabled="!item.accord"
                              v-if="isUserPoints === 2 && item.availableNum != '0'"
                      >
                          获取积分
                      </b-button>
                      <div class="bg-sold" v-if="Number(item.availableNum) === 0">
              <span>已售罄
                <div v-if="item.arrivalTime && item.arrivalTime != ''">
                  预计到货时间：{{ item.arrivalTime }}
                </div></span>
                      </div>
                      <div class="bg-sold" v-else-if="Number(item.totalNumber) > Number(item.availableNum)">
                          <span>库存不足</span>
                      </div>
                      <p
                          class="bg-sold"
                          v-else-if="
                          item.preferenticePrice !== null &&
                          (item.availableNum === '0' ? '' : !item.isInvalid)
                        ">
                          <span>失效</span>
                      </p>
                  </div>
                  <div class="pruduct-name">
                      <span class="RX-red" v-if="item.category&&item.category=='1'">甲</span>
                      <span class="RX-red" v-if="item.category&&item.category=='2'">乙</span>
                      <span class="RX-red" v-if="item.category&&item.category=='3'">丙</span>
                      <span class="name">【{{ item.specifications }}】{{ item.productName }}</span>
                  </div>
              <div class="" style="display: flex" v-if="item.activityVOList.length > 0 && isOtherAccount==true"
                    v-for="(activity, acIndex) in item.activityVOList" :key="acIndex">
                  <ActivityLine activeName="满减"
                                v-if="activity.type == 1"
                                :type="activity.type"
                                bgColor="#FFF3F3"
                                borderColor="#FFF3F3"
                                color="#000000"
                                :activeContent="activity.activityName" />
                  <ActivityLine activeName="返红包"
                                v-if="activity.type == 2 || activity.type == 4"
                                :type="activity.type"
                                bgColor="#FFF5D6"
                                borderColor="#FFF5D6"
                                color="#000000"
                                :activeContent="activity.activityName" />
              </div>
              <p class="product-company">{{ item.produceFactory | ellipsis }}</p>
                  <p class="product-company" v-if="item.nearEffectivePeriod">
                      <span>效期:</span> {{ item.nearEffectivePeriod | formatDate }}
                  </p>
              <p v-else class="product-company"><span>效期</span></p>
              <p class="product-company" v-if="item.lastReturnTime"><span>最后退货时间:</span>{{item.lastReturnTime}}</p>
              </div>

          <!--鼠标移入商品时，显示右侧商品信息-->
          <template v-slot:content>
            <div class="product-tooltip-box">
              <p class="product-name-box">
              <span :style="{background: item.prescription.bgColor}" v-if="item.prescription && item.prescription.text">{{ item.prescription.text }}</span>
              <font class="product-hbimg-jf" v-if="item.productAttribute == '1'">
                  返利
              </font>
              <img class="tail-hb-img" v-if="item.activityVOList[0]&&item.activityVOList[0].isIntegralCommodity&&item.activityVOList[0].isIntegralCommodity==1" src="@images/2.gif">
              【{{ item.specifications }}】{{item.productName }}
              </p>
              <!-- 价格和优惠活动 -->
              <div class="product-active-price">
                          <div class="label-box" v-if="item.superDiscountProductPrice">
                              <p class="label-purchase">特价</p>
                              <p>
                                  <span class="symbol">¥</span>
                                  <span class="purchase-price" v-if="!item.superDiscountProductPrice">价格登录后可见</span>
                                  <span class="purchase-price" v-if="isExternal">价格不可见</span>
                                  <span class="purchase-price" v-if="item.superDiscountProductPrice&&!isExternal">{{item.superDiscountProductPrice}}
                                  <span v-if="item.sellUnit">{{'/'+item.sellUnit}}</span></span>
                              </p>
                              <div class="tj-box">{{item.superDiscountProductNum}}{{item.sellUnit}}起购</div>
                          </div>
                          <div class="label-box" v-if="item.superDiscountProductPrice">
                              <p>采购价格</p>
                              <p style="text-decoration: line-through;">
                                  <span>¥</span>
                                  <span v-if="!item.preferenticePrice">价格登录后可见</span>
                                  <span v-if="isExternal">价格不可见</span>
                                  <span v-if="item.preferenticePrice&&!isExternal">{{item.preferenticePrice}}
                                  <span v-if="item.sellUnit">{{'/'+item.sellUnit}}</span></span>
                              </p>
                          </div>
                          <div class="label-box" v-if="!item.superDiscountProductPrice">
                              <p class="label-purchase">采购价格</p>
                              <p>
                                  <span class="symbol">¥</span>
                                  <span class="purchase-price" v-if="!item.preferenticePrice">价格登录后可见</span>
                                  <span class="purchase-price" v-if="isExternal">价格不可见</span>
                                  <span class="purchase-price" v-if="item.preferenticePrice&&!isExternal">
                                    {{item.preferenticePrice }}<span v-if="item.sellUnit">{{'/'+item.sellUnit}}</span>
                                  </span>
                              </p>
                          </div>
                          <div class="label-box">
                              <p>建议零售价</p>
                              <p>¥{{ item.suggestedPrice }}</p>
                          </div>
                          <div class="label-box">
                              <p>毛利率</p>
                              <p>{{ item.grossProfitRate }}%</p>
                          </div>
                          <div class="label-box" v-if="item.activityVOList.length>0">
                              <p class="label-active" style="width: 75px">活动</p>
                              <div>
                                <div v-if="item.activityVOList.length > 0 && isOtherAccount==true"
                                     v-for="(activity, acIndex) in item.activityVOList" :key="acIndex">
                                  <ActivityLine activeName="满减"
                                                v-if="activity.type == 1"
                                                :type="activity.type"
                                                bgColor="#FFF3F3"
                                                borderColor="#FFF3F3"
                                                color="#000000"
                                                :isDetail="true"
                                                :activeContent="activity.activityName" />
                                  <ActivityLine activeName="返红包"
                                                v-if="activity.type == 2 || activity.type == 4"
                                                :type="activity.type"
                                                bgColor="#FFF5D6"
                                                borderColor="#FFF5D6"
                                                color="#000000"
                                                :isDetail="true"
                                                :activeContent="activity.activityName" />
                                </div>
                              </div>
                          </div>
                      </div>
              <div class="product-info-basic-box">
                          <ul>
                              <li>
                                  <p>批准文号</p>
                                  <p>{{ item.approvalNumber }}</p>
                              </li>
                              <li>
                                  <p>剂型</p>
                                  <p>{{ item.formulation }}</p>
                              </li>
                              <li>
                                  <p>包装</p>
                                  <p>{{ item.sellUnit }}</p>
                              </li>
                              <li>
                                  <p>件装</p>
                                  <p>
                                      {{ item.packageNumber }}
                                  </p>
                              </li>
                              <li>
                                  <p>生产厂家</p>
                                  <p>{{ item.produceFactory | ellipsisss }}</p>
                              </li>
                              <li v-if="item.produceAddress">
                                  <p>产地</p>
                                  <p>{{ item.produceAddress}}</p>
                              </li>
                              <li>
                                  <p>处方分类</p>
                                  <p>
                                      <!-- <span
                                        v-if="
                                          pageDataSource.prescription &&
                                            pageDataSource.prescription.type
                                        "
                                        >{{ pageDataSource.prescription }}</span> -->
                                      <span v-if="item.prescription">{{ item.prescription.type }}{{ item.prescription.text }}</span>
                                  </p>
                              </li>
                              <li>
                                  <p>效期</p>
                                  <p v-if="item.nearEffectivePeriod">
                                      {{ item.nearEffectivePeriod | formatDate }}
                                  </p>
                                  <p v-else></p>
                              </li>
                              <li v-if="Number(item.isMediumPackage) === 1">
                                  <p>中包装数量</p>
                                  <p>{{ item.mediumPackage }}</p>
                              </li>
                              <li v-if="isCurrentLogin && !isExternal && item.isFactoryAndBelongProductFactory == 0">
                                  <p>库存</p>
                                  <p>{{item.availableNum > 99 ? "99+" : item.availableNum }}</p>
                              </li>
                              <li v-if="isCurrentLogin && !isExternal && item.isFactoryAndBelongProductFactory == 1">
                                  <p>库存</p>
                                  <p>{{item.availableNum}}</p>
                              </li>
                          </ul>
                      </div>
            </div>
          </template>
        </b-tooltip>


        <div class="product-price-box">
            <div class="SpecialPrice" v-if="!isExternal && item.superDiscountProductPrice">
                <span>特价</span><span>￥{{ item.superDiscountProductPrice }}</span><span v-if="item.superDiscountProductNum">{{item.superDiscountProductNum}}{{item.sellUnit}}起购</span>
            </div>
          <div class="price-box" style="flex-flow: wrap;" v-else>
            <p class="unit-price">¥</p>
            <p class="no-login-in" v-if="isExternal">价格不可见</p>
            <template v-else-if="item.preferenticePrice !== null">
              <p class="product-price">
                  {{ item.preferenticePrice }}<span v-if="item.sellUnit">{{ "/" + item.sellUnit }}</span>
              </p>
              <!--有活动且活动的价格 > 0-->
              <p class="product-price-activity" v-if="item.activityVOList.length && item.activityVOList[0].activityZhPrice > 0">
                  活动价约¥{{ item.activityVOList[0].activityZhPrice }}
              </p>
              <div class="price-box-mlls">
                <div class="price-box-mll" v-if="item.suggestedPrice > 0 && item.grossProfitRate > 0">
                  零售价：{{ item.suggestedPrice}}
                  <span v-if="item.sellUnit">{{ "/" + item.sellUnit }}</span>&nbsp;&nbsp;
                  毛利率{{Number(item.grossProfitRate).toFixed(0) }}%
                </div>
              </div>
            </template>

            <p class="no-login-in" v-else>价格登录后可见</p>
          </div>
          <div
            class="md-num-product"
            v-if="item.preferenticePrice !== null && !isExternal"
          >
            <div
              class="product-md-package"
              v-if="
                Number(item.isMediumPackage) === 1 && item.preferenticePrice
              "
            >
              中包装(1中包装={{ item.mediumPackage }}{{ item.sellUnit }})
            </div>
            <div class="product-number-input">
              <div class="product-input-box">
                <span
                  class="product-minus"
                  v-if="!item.isCheckMin"
                  @click="handleMinus(item)">-</span>
                <span v-else class="product-bg-minus product-minus">-</span>
                <div class="product-field">
                  <div class="product-control">
                    <input
                      class="product-input"
                      type="number"
                      placeholder="0"
                      maxlength="4"
                      v-model.trim.number="item.totalNumber"
                      @input="handleInput(item, item.productId)"
                      @blur="handleBlur"
                      :disabled="
                        item.preferenticePrice !== null &&
                        item.availableNum === '0'
                      "
                    />
                  </div>
                </div>
                <span
                  v-if="item.isFlag && item.availableNum != '0'"
                  class="product-sign"
                  @click="handleSign(item)"
                  >+</span
                >
                <span v-else class="product-bg-minus product-sign">+</span>
              </div>
              <div
                :key="item.productId"
                class="messages-bg-product"
                v-if="item.isMin || item.isMax"
              >
                <p class="help-bg is-danger-bg" v-if="item.isMin">
                  您输入的购买数量小于最低限制数量{{ item.notLowerSale }}！
                </p>
                <p class="help-bg is-danger-bg" v-if="item.isMax">
                  您输入的购买数量大于最多限制数量{{ item.limitSaleNumber }}！
                </p>
              </div>
            </div>
            <div
              class="product-md-count"
              v-if="Number(item.isMediumPackage) === 1"
            >
              实际购买数量<span>{{ item.Package }}{{ item.sellUnit }}</span>
            </div>
          </div>

          <div class="car-img">
            <span
              class="no-forbid"
              v-if="
                item.preferenticePrice !== null &&
                item.availableNum != '0' &&
                !item.isBtn &&
                item.isInvalid &&
                Number(item.totalNumber) <= Number(item.availableNum) &&
                !isExternal
              "
              @click.stop="handelImg(item)"
            >
              <img src="./images/car.png" alt="" />
            </span>
            <span v-else class="forbid">
              <img src="./images/grey.png" alt="" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="mask" v-if="isShowMask">
      <PointsAnimation :pointsNum="coinsNum"></PointsAnimation>
    </div>
    <div class="success-bg" v-if="isSuccess">
        <div class="success" v-if="nowItem.superDiscountProductPrice">
            <div v-if="totalNumber > nowItem.superDiscountProductNum">
                <p>前{{nowItem.superDiscountProductNum}}{{nowItem.sellUnit}}&nbsp;&nbsp;{{nowItem.superDiscountProductPrice}}元{{nowItem.sellUnit}}</p>
                <p>超过{{nowItem.superDiscountProductNum}}{{nowItem.sellUnit}}&nbsp;&nbsp;{{nowItem.preferenticePrice}}元{{nowItem.sellUnit}}</p>
            </div>
            <div v-else>
                <p>特价商品</p>
                <p>成功加入购物车</p>
            </div>
        </div>
        <div class="success" v-else>已加入购物车
            <p v-if="isMediumPackage == 1">购买{{totalNumber}}个中包装，实际购买{{Package}}{{sellUnit}}</p>
        </div>
    </div>
  </div>
</template>
<script>
import { PointsAnimation } from "@components/points-animation";
import { AccountStorage } from "@services/account";
import { Account } from "@services/account";
import { ActivityLine } from "@components/activity-line";
const accountStorage = new AccountStorage();
const account = new Account();
// 点击获取积分
const readIntegral =
  "/pjyy-deepexi-product-center/api/v1/product/PcActivity/readIntegral";
// 加入购物车
const addGoodsToCartList =
  "/pjyy-deepexi-order-center/api/v1/cart/addGoodsToCartList";
export default {
  components: {
    PointsAnimation,
    ActivityLine
  },
  props: {
    //栏目类型(今日爆款hot、进口药品imported、猜你喜欢like、新品上架new  等等)
    columnType: {
      type: String,
      default: ""
    },
    //商品数据
    productList: {
      type: Array,
      default: () => []
    },
    isShowTitle: {
      type: Boolean,
      default: true
    },
    isUserPoints: {
      type: Number,
      default: null
    },
    isClickTab: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isShowMask: false,
      coinsNum: -1,
      isSuccess: false,
      ind: "",
      totalNumber: null,
      limitSaleNumber: null, // 最大
      notLowerSale: null, // 最小
      mediumPackage: "", // 中包装
      isExternal: false,
      sellUnit: "",
      isMediumPackage: "",
      Package: "",
      isOtherAccount:true,
      nowItem:null
    };
  },
  computed:{
    isCurrentLogin() {
      //获取登录状态
      return account.isLogined();
    },
  },
  mounted() {
    let userInfo = accountStorage.getCacheUserInfo();
    let userId = userInfo.account;
    if(userId.charAt(0)=='8') {
      this.isOtherAccount = true;
    }
    if (userInfo) {
      this.isExternal = userInfo.type == 1 ? true : false;
    }
  },
  watch: {
    productList: {
      handler(val) {
        this.productList = val;
        this.productList.forEach((item,index) => {
          if (index.toString().indexOf("4") != -1 || index.toString().indexOf("9") != -1){
            item.isShowModel = true;
          }else{
            item.isShowModel = false;
          }
          // item.totalNumber =
          //   item.activityCount != 0 && item.activityCount != null
          //     ? (item.totalNumber = item.activityCount)
          //     : item.notLowerSale != 0 && item.notLowerSale != null
          //     ? (item.totalNumber = item.notLowerSale)
          //     : (item.totalNumber = 1);
           //update by lxr 2021-06-30
          //  item.totalNumber =
          //   item.activityVOList.length>0&&item.activityVOList[0].activityCount
          //     ? (item.totalNumber =item.activityVOList[0].activityCount )
          //     : item.notLowerSale != 0 && item.notLowerSale != null
          //     ? (item.totalNumber = item.notLowerSale)
          //     : (item.totalNumber = 1);

          if (item.activityVOList.length>0&&item.activityVOList[0].activityCount) {
            if (item.isMediumPackage==1) {
              if (item.activityVOList[0].activityCount/item.mediumPackage>0) {
                item.totalNumber = item.activityVOList[0].activityCount/item.mediumPackage
              } else {
                item.totalNumber = 1
              }
            } else {
             item.totalNumber = item.activityVOList[0].activityCount
            }
          }else{
              if(item.superDiscountProductNum){//特价商品的数量是否有值，有值代表此品种是特价品种，数量则为特价的最低起购数量
                  if(item.oldNum){
                      item.totalNumber = item.superDiscountProductNum + item.oldNum
                  }else{
                      item.totalNumber = item.superDiscountProductNum
                  }
              }else{
                  item.totalNumber = item.notLowerSale || 1

              }
          }
          item.isFlag = true;
          // item.isCheckMin = true;
          item.isCheckMin = item.totalNumber <= 1 ? true : false;
          item.isBtn = false;
          item.isMin = false;
          item.isMax = false;
          item.Package =
            item.isMediumPackage === 1
              ? item.totalNumber * item.mediumPackage
              : "";
        });
      },
      immediate: true,
      deep: true
    }
  },
  filters: {
    timeFilter(ms) {
      function add0(m) {
        return m < 10 ? "0" + m : m;
      }
      if (ms) {
        let time = new Date(ms);
        let year = time.getFullYear();
        let month = time.getMonth() + 1;
        let date = time.getDate();
        return year + "-" + add0(month) + "-" + add0(date) + " ";
      } else {
        return "";
      }
    }
  },
  methods: {
    handleInput(item, id) {
      this.ind = id;
      this.totalNumber = item.totalNumber;
      this.limitSaleNumber = item.limitSaleNumber;
      this.notLowerSale = item.notLowerSale;
      this.mediumPackage = item.totalNumber * item.mediumPackage;
      if ((item.totalNumber + "").length >= 5) {
        return (item.totalNumber = 9999);
      }
      if (item.totalNumber == "") {
        item.isBtn = true;
        item.totalNumber = Number(item.totalNumber);
        if (item.totalNumber === 0) {
          item.totalNumber = null;
          item.isCheckMin = true;
        }
      } else {
        item.isBtn = false;
      }
      if (item.totalNumber > 1) {
        item.isCheckMin = false;
      } else if (item.totalNumber === 1) {
        item.isCheckMin = true;
      }
      if (item.limitSaleNumber != 0 || item.notLowerSale != 0) {
        if (item.totalNumber < item.notLowerSale) {
          item.isMin = true;
          if (item.totalNumber <= item.notLowerSale) {
            item.isCheckMin = true;
          }
        } else {
          item.isMin = false;
        }
        if (item.totalNumber > item.limitSaleNumber) {
          item.isMax = true;
          if (item.totalNumber >= item.limitSaleNumber) {
            item.isFlag = false;
          }
        } else {
          item.isMax = false;
          item.isFlag = true;
        }
      }
      this.productList.forEach(item => {
        if (item.productId === this.ind) {
          this.$forceUpdate();
          item.Package = item.totalNumber * item.mediumPackage;
        }
      });
    },
    handleBlur() {},
    // --
    handleMinus(item) {
      item.Package = (item.totalNumber - 1) * item.mediumPackage;
      if (item.totalNumber - 1 <= item.limitSaleNumber) {
        item.isFlag = true;
      }
      if (
        item.totalNumber - 1 === 1 ||
        item.totalNumber - 1 === item.notLowerSale
      ) {
        item.isCheckMin = true;
      }
      this.$forceUpdate();
      item.totalNumber--;
      item.isMax = false;
    },
    // ++
    handleSign(item) {
      this.totalNumber = item.totalNumber + 1;
      this.ind = item.productId;
      item.Package = (item.totalNumber + 1) * item.mediumPackage;
      if (item.totalNumber + 1 <= item.notLowerSale) {
        item.isCheckMin = true;
      }
      if (item.totalNumber + 1 > item.notLowerSale) {
        item.isCheckMin = false;
      }
      if (item.totalNumber + 1 >= item.notLowerSale) {
        item.isFlag = true;
      }
      if (item.totalNumber + 1 === item.limitSaleNumber) {
        item.isFlag = false;
      }
      // if (item.totalNumber + 1 > 1) {
      //   console.log("33333");
      //   item.isCheckMin = true;
      // }
      if (item.totalNumber != 0) {
        item.isBtn = false;
      }
      this.$forceUpdate();
      item.isMin = false;
      item.totalNumber++;
    },
    //获取用户ID
    getUserId() {
      let userInfo = accountStorage.getCacheUserInfo();
      if (userInfo) {
        return userInfo.userId;
      }
      return;
    },
    // 加入购物车
    handelImg(item) {
      let data = {
          //  2021-10-27 huangyi 修改productId不转为number类型
          // productId: Number(item.productId || item.id),
          productId: item.productId || item.id,
        userId: Number(this.getUserId()),
        totalNumber: item.totalNumber || 1
      };
      this.$getHttpClient()
        .post(addGoodsToCartList, data)
        .then(res => {
          if (res.data.code === "200") {
            this.totalNumber = item.totalNumber || 1;
            this.sellUnit = item.sellUnit;
            this.Package = item.Package;
            this.isMediumPackage = item.isMediumPackage;
            this.nowItem = item;
            this.isSuccess = true;
            this.isSuccess = true;
            setTimeout(() => {
              this.isSuccess = false;
            }, 3000);
            this.$emit("handelImg", this.handelImg);
          }
        });
    },
    toDetail(event) {
      if (event.id || event.productId) {
        window.sessionStorage.setItem("isClickTab", this.isClickTab);
        this.$router.push({
          name: "details",
          params: {
            id: event.productId || event.id,
            sale: event.totalNumber || 1
          }
        });
      }
    },
    // 获取积分
    toAddIntegral(item) {
      document.body.parentNode.style.overflow = "hidden";
      this.isShowMask = true;
      this.coinsNum = item.readIntegral;
      this.$getHttpClient()
        .get(
          `${readIntegral}?productId=${item.id}&activityId=${item.activityId}`
        )
        .then(res => {
          if (res.data.code === "100002") {
            this.$buefy.toast.open({
              message: res.data.msg,
              type: "is-danger"
            });
            this.isShowMask = false;
          } else {
            this.isShowMask = true;
            this.productList.forEach(it => {
              if (item.id === it.id) {
                it.accord = false;
              }
            });
          }
        });
      if (this.isShowMask) {
        setTimeout(() => {
          document.body.parentNode.style.overflow = "";
          this.isShowMask = false;
        }, 3000);
      }
    },
    // 换一批
    toChangeList() {
      this.$emit("toChangeList", this.columnType);
    },
    // 查看更多
    toLookMore() {
      if (this.columnType === "new") {
        this.$router.push({
          path: "/channel/channel-detail",
          query: {
            activeIndex: 2
          }
        });
      }
    }
  }
};
</script>
<style src="./product-list.less" lang="less"></style>
