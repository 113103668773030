import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { PointsAnimation } from "@components/points-animation";
import { AccountStorage } from "@services/account";
import { Account } from "@services/account";
import { Countdown } from "@components/countdown";
import { ActivityLine } from "@components/activity-line";
var accountStorage = new AccountStorage();
var account = new Account();
// 加入购物车
var addGoodsToCartList = "/pjyy-deepexi-order-center/api/v1/cart/addGoodsToCartList";
// 点击获取积分
var readIntegral = "/pjyy-deepexi-product-center/api/v1/product/PcActivity/readIntegral";
export default {
  data: function data() {
    return {
      isShowMask: false,
      // 积分弹窗显示隐藏
      coinsNum: -1,
      isSuccess: false,
      isLimit: false,
      isSale: false,
      ind: "",
      totalNumber: null,
      limitSaleNumber: null,
      // 最大
      notLowerSale: null,
      // 最小
      mediumPackage: "",
      // 中包装
      isExternal: false,
      isGroupBook: false,
      groupTime: '',
      sellUnit: '',
      isMediumPackage: '',
      Package: '',
      isOtherAccount: true,
      nowItem: null
    };
  },
  props: {
    // 接收的数据列表
    productList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isUserPoints: {
      type: Number,
      default: null
    },
    isShow: {
      type: Boolean,
      default: false
    },
    isClickTab: {
      type: Number,
      default: null
    }
  },
  computed: {
    isCurrentLogin: function isCurrentLogin() {
      //获取登录状态
      return account.isLogined();
    }
  },
  watch: {
    productList: {
      handler: function handler(val) {
        this.productList = val;
        this.productList.forEach(function (item) {
          // item.totalNumber =
          //   item.activityCount ? item.activityCount : item.notLowerSale != 0 && item.notLowerSale != null ? item.notLowerSale : 1
          //update by lxr 2021-06-30
          //  item.totalNumber =
          //   item.activityVOList.length>0&&item.activityVOList[0].activityCount ? item.activityVOList[0].activityCount : (item.notLowerSale != 0 && item.notLowerSale != null ? item.notLowerSale : 1)

          if (item.activityVOList.length > 0 && item.activityVOList[0].activityCount) {
            if (item.isMediumPackage == 1) {
              if (item.activityVOList[0].activityCount / item.mediumPackage > 0) {
                item.totalNumber = item.activityVOList[0].activityCount / item.mediumPackage;
              } else {
                item.totalNumber = 1;
              }
            } else {
              item.totalNumber = item.activityVOList[0].activityCount;
            }
          } else {
            if (item.superDiscountProductNum) {
              //特价商品的数量是否有值，有值代表此品种是特价品种，数量则为特价的最低起购数量
              if (item.oldNum) {
                item.totalNumber = item.superDiscountProductNum + item.oldNum;
              } else {
                item.totalNumber = item.superDiscountProductNum;
              }
            } else {
              item.totalNumber = item.notLowerSale || 1;
            }
          }
          item.isFlag = true;
          item.isCheckMin = item.totalNumber <= 1 ? true : false;
          item.isBtn = false;
          item.isMin = false;
          item.isMax = false;
          item.Package = item.isMediumPackage === 1 ? item.totalNumber * item.mediumPackage : "";
        });
      },
      immediate: true,
      deep: true
    }
  },
  filters: {
    timeFilter: function timeFilter(ms) {
      function add0(m) {
        return m < 10 ? '0' + m : m;
      }
      if (ms) {
        var time = new Date(ms);
        var year = time.getFullYear();
        var month = time.getMonth() + 1;
        var date = time.getDate();
        return year + '-' + add0(month) + '-' + add0(date) + ' ';
      } else {
        return '';
      }
    }
  },
  components: {
    PointsAnimation: PointsAnimation,
    Countdown: Countdown,
    ActivityLine: ActivityLine
  },
  mounted: function mounted() {
    var userInfo = accountStorage.getCacheUserInfo();
    var userId = userInfo.account;
    if (userId.charAt(0) == '8') {
      this.isOtherAccount = true;
    }
    if (userInfo) {
      this.isExternal = userInfo.type == 1 ? true : false;
    }
  },
  methods: {
    handleInput: function handleInput(item, id) {
      var _this = this;
      this.ind = id;
      this.totalNumber = item.totalNumber;
      this.limitSaleNumber = item.limitSaleNumber;
      this.notLowerSale = item.notLowerSale;
      this.mediumPackage = item.totalNumber * item.mediumPackage;
      if ((item.totalNumber + "").length >= 5) {
        return item.totalNumber = 9999;
      }
      if (item.totalNumber == "") {
        item.isBtn = true;
        item.totalNumber = Number(item.totalNumber);
        if (item.totalNumber === 0) {
          item.totalNumber = null;
          item.isCheckMin = true;
        }
      } else {
        item.isBtn = false;
      }
      if (item.totalNumber > 1) {
        item.isCheckMin = false;
      } else if (item.totalNumber === 1) {
        item.isCheckMin = true;
      }
      if (item.limitSaleNumber != 0 || item.notLowerSale != 0) {
        if (item.totalNumber < item.notLowerSale) {
          item.isMin = true;
          if (item.totalNumber <= item.notLowerSale) {
            item.isCheckMin = true;
          }
        } else {
          item.isMin = false;
        }
        if (item.totalNumber > item.limitSaleNumber) {
          item.isMax = true;
          if (item.totalNumber >= item.limitSaleNumber) {
            item.isFlag = false;
          }
        } else {
          item.isMax = false;
          item.isFlag = true;
        }
      }
      this.productList.forEach(function (item) {
        if (item.productId === _this.ind) {
          _this.$forceUpdate();
          item.Package = item.totalNumber * item.mediumPackage;
        }
      });
    },
    handleBlur: function handleBlur() {},
    // --
    handleMinus: function handleMinus(item) {
      item.Package = (item.totalNumber - 1) * item.mediumPackage;
      if (item.totalNumber - 1 <= item.limitSaleNumber) {
        item.isFlag = true;
      }
      if (item.totalNumber - 1 === 1 || item.totalNumber - 1 === item.notLowerSale) {
        item.isCheckMin = true;
      }
      this.$forceUpdate();
      item.totalNumber--;
      item.isMax = false;
    },
    // ++
    handleSign: function handleSign(item) {
      this.totalNumber = item.totalNumber + 1;
      this.ind = item.productId;
      item.Package = (item.totalNumber + 1) * item.mediumPackage;
      if (item.totalNumber + 1 <= item.notLowerSale) {
        item.isCheckMin = true;
      }
      if (item.totalNumber + 1 > item.notLowerSale) {
        item.isCheckMin = false;
      }
      if (item.totalNumber + 1 >= item.notLowerSale) {
        item.isFlag = true;
      }
      if (item.totalNumber + 1 === item.limitSaleNumber) {
        item.isFlag = false;
      }
      // if (item.totalNumber + 1 > 1) {
      //   console.log("33333");
      //   item.isCheckMin = true;
      // }
      if (item.totalNumber != 0) {
        item.isBtn = false;
      }
      this.$forceUpdate();
      item.isMin = false;
      item.totalNumber++;
    },
    // handleInput() {
    //   this.$forceUpdate();
    // },
    // 输入数量的时候
    onBlur: function onBlur(num) {
      if (num <= 0) {
        num = 1;
        this.$forceUpdate();
      }
    },
    // 获取积分按钮
    getIntegral: function getIntegral(item) {
      var _this2 = this;
      document.body.parentNode.style.overflow = "hidden";
      this.isShowMask = true;
      this.coinsNum = item.readIntegral;
      this.$getHttpClient().get("".concat(readIntegral, "?productId=").concat(item.id, "&activityId=").concat(item.activityId)).then(function (res) {
        if (res.data.code === "100002") {
          _this2.$buefy.toast.open({
            message: res.data.msg,
            type: "is-danger"
          });
          _this2.isShowMask = false;
        } else {
          _this2.isShowMask = true;
          _this2.productList.forEach(function (it) {
            if (item.id === it.id) {
              it.accord = false;
            }
          });
        }
      });
      if (this.isShowMask) {
        setTimeout(function () {
          document.body.parentNode.style.overflow = "";
          _this2.isShowMask = false;
        }, 3000);
      }
    },
    //获取用户ID
    getUserId: function getUserId() {
      var userInfo = accountStorage.getCacheUserInfo();
      if (userInfo) {
        return userInfo.userId;
      }
      return;
    },
    // 加入购物车
    toAddCart: function toAddCart(item) {
      var _this3 = this;
      if (this.isShow) {
        item.totalNumber = item.salecount;
      }
      var data = {
        productId: Number(item.productId || item.id),
        userId: Number(this.getUserId()),
        totalNumber: item.totalNumber || 1
      };
      this.$getHttpClient().post(addGoodsToCartList, data).then(function (res) {
        if (res.data.code === "200") {
          _this3.totalNumber = item.totalNumber || 1;
          _this3.sellUnit = item.sellUnit;
          _this3.Package = item.Package;
          _this3.isMediumPackage = item.isMediumPackage;
          _this3.nowItem = item;
          _this3.isSuccess = true;
          setTimeout(function () {
            _this3.isSuccess = false;
            if (_this3.isShow && _this3.isUserPoints === 2) {
              _this3.$router.push({
                path: '/cart/cartList'
              });
            }
          }, 3000);
          _this3.$emit("toAddCart", _this3.toAddCart);
        }
      });
    },
    // 跳转到详情页
    toDetails: function toDetails(val) {
      if (val.id || val.productId) {
        window.sessionStorage.setItem("isClickTab", this.isClickTab);
        this.$router.push({
          name: "details",
          params: {
            id: val.id || val.productId,
            sale: val.totalNumber
          }
        });
      }
    },
    countDownStop: function countDownStop() {
      this.$emit('countDownStop');
    }
  }
};