import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "list-box"
  }, [_vm._l(_vm.productList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "list-wrap"
    }, [_vm.isShow && _vm.isUserPoints === 2 ? _c('div', {
      staticClass: "imgTitle"
    }, [_c('span', [_vm._v(_vm._s(item.groupActivityName))])]) : _vm._e(), _c('div', {
      staticClass: "list"
    }, [_c('b-tooltip', {
      attrs: {
        "position": "is-right",
        "multilined": "",
        "type": "is-light"
      },
      scopedSlots: _vm._u([{
        key: "content",
        fn: function fn() {
          return [_c('div', {
            staticClass: "product-tooltip-box"
          }, [_c('p', {
            staticClass: "product-name-box"
          }, [item.prescription && item.prescription.text ? _c('span', {
            style: {
              background: item.prescription.bgColor
            }
          }, [_vm._v(_vm._s(item.prescription.text))]) : _vm._e(), item.productAttribute == '1' ? _c('font', {
            staticClass: "product-hbimg-jf"
          }, [_vm._v(" 返利 ")]) : _vm._e(), item.activityVOList[0] && item.activityVOList[0].isIntegralCommodity && item.activityVOList[0].isIntegralCommodity == 1 ? _c('img', {
            staticClass: "tail-hb-img",
            attrs: {
              "src": require("@images/2.gif")
            }
          }) : _vm._e(), _vm._v(" 【" + _vm._s(item.specifications) + "】" + _vm._s(item.productName) + " ")], 1), _c('div', {
            staticClass: "product-active-price"
          }, [item.superDiscountProductPrice ? _c('div', {
            staticClass: "label-box"
          }, [_c('p', {
            staticClass: "label-purchase"
          }, [_vm._v("特价")]), _c('p', [_c('span', {
            staticClass: "symbol"
          }, [_vm._v("¥")]), !item.superDiscountProductPrice ? _c('span', {
            staticClass: "purchase-price"
          }, [_vm._v("价格登录后可见")]) : _vm._e(), _vm.isExternal ? _c('span', {
            staticClass: "purchase-price"
          }, [_vm._v("价格不可见")]) : _vm._e(), item.superDiscountProductPrice && !_vm.isExternal ? _c('span', {
            staticClass: "purchase-price"
          }, [_vm._v(_vm._s(item.superDiscountProductPrice) + " "), item.sellUnit ? _c('span', [_vm._v(_vm._s('/' + item.sellUnit))]) : _vm._e()]) : _vm._e()]), item.superDiscountProductNum ? _c('div', {
            staticClass: "tj-box"
          }, [_vm._v(_vm._s(item.superDiscountProductNum) + _vm._s(item.sellUnit) + "起购")]) : _vm._e()]) : _vm._e(), item.superDiscountProductPrice ? _c('div', {
            staticClass: "label-box"
          }, [_c('p', [_vm._v("采购价格")]), _c('p', {
            staticStyle: {
              "text-decoration": "line-through"
            }
          }, [_c('span', [_vm._v("¥")]), !item.preferenticePrice ? _c('span', [_vm._v("价格登录后可见")]) : _vm._e(), _vm.isExternal ? _c('span', [_vm._v("价格不可见")]) : _vm._e(), item.preferenticePrice && !_vm.isExternal ? _c('span', [_vm._v(_vm._s(item.preferenticePrice) + " "), item.sellUnit ? _c('span', [_vm._v(_vm._s('/' + item.sellUnit))]) : _vm._e()]) : _vm._e()])]) : _c('div', {
            staticClass: "label-box"
          }, [_c('p', {
            staticClass: "label-purchase"
          }, [_vm._v("采购价格")]), _c('p', [_c('span', {
            staticClass: "symbol"
          }, [_vm._v("¥")]), !item.preferenticePrice ? _c('span', {
            staticClass: "purchase-price"
          }, [_vm._v("价格登录后可见")]) : _vm._e(), _vm.isExternal ? _c('span', {
            staticClass: "purchase-price"
          }, [_vm._v("价格不可见")]) : _vm._e(), item.preferenticePrice && !_vm.isExternal ? _c('span', {
            staticClass: "purchase-price"
          }, [_vm._v(_vm._s(item.preferenticePrice) + " "), item.sellUnit ? _c('span', [_vm._v(_vm._s('/' + item.sellUnit))]) : _vm._e()]) : _vm._e()])]), _c('div', {
            staticClass: "label-box"
          }, [_c('p', [_vm._v("建议零售价")]), _c('p', [_vm._v("¥" + _vm._s(item.suggestedPrice))])]), _c('div', {
            staticClass: "label-box"
          }, [_c('p', [_vm._v("毛利率")]), _c('p', [_vm._v(_vm._s(item.grossProfitRate) + "%")])]), item.activityVOList.length > 0 ? _c('div', {
            staticClass: "label-box"
          }, [_c('p', {
            staticClass: "label-active"
          }, [_vm._v("活动")]), _vm._l(item.activityVOList, function (activity, acIndex) {
            return item.activityVOList.length > 0 && _vm.isOtherAccount == true ? _c('div', {
              key: acIndex,
              staticStyle: {
                "display": "flex"
              }
            }, [activity.type == 1 ? _c('ActivityLine', {
              attrs: {
                "activeName": "满减",
                "type": activity.type,
                "bgColor": "#FFF3F3",
                "borderColor": "#FFF3F3",
                "color": "#000000",
                "isDetail": true,
                "activeContent": activity.activityName
              }
            }) : _vm._e()], 1) : _vm._e();
          })], 2) : _vm._e(), _vm._l(item.activityVOList, function (itema, index) {
            return _c('div', {
              key: index,
              staticClass: "label-box"
            }, [index > 0 ? [_c('p', {
              staticClass: "label-active"
            }), _vm._l(item.activityVOList, function (activity, acIndex) {
              return item.activityVOList.length > 0 && _vm.isOtherAccount == true ? _c('div', {
                key: acIndex,
                staticStyle: {
                  "display": "flex"
                }
              }, [activity.type == 2 || activity.type == 4 ? _c('ActivityLine', {
                attrs: {
                  "activeName": "返红包",
                  "type": activity.type,
                  "bgColor": "#FFF5D6",
                  "borderColor": "#FFF5D6",
                  "color": "#000000",
                  "isDetail": true,
                  "activeContent": activity.activityName
                }
              }) : _vm._e()], 1) : _vm._e();
            })] : _vm._e()], 2);
          })], 2), _c('div', {
            staticClass: "product-info-basic-box"
          }, [_c('ul', [_c('li', [_c('p', [_vm._v("批准文号")]), _c('p', [_vm._v(_vm._s(item.approvalNumber))])]), _c('li', [_c('p', [_vm._v("剂型")]), _c('p', [_vm._v(_vm._s(item.formulation))])]), _c('li', [_c('p', [_vm._v("包装")]), _c('p', [_vm._v(_vm._s(item.sellUnit))])]), _c('li', [_c('p', [_vm._v("件装")]), _c('p', [_vm._v(" " + _vm._s(item.packageNumber) + " ")])]), _c('li', [_c('p', [_vm._v("生产厂家")]), _c('p', [_vm._v(_vm._s(_vm._f("ellipsisss")(item.produceFactory)))])]), item.produceAddress ? _c('li', [_c('p', [_vm._v("产地")]), _c('p', [_vm._v(_vm._s(item.produceAddress))])]) : _vm._e(), _c('li', [_c('p', [_vm._v("处方分类")]), _c('p', [item.prescription ? _c('span', [_vm._v(" " + _vm._s(item.prescription.type) + " " + _vm._s(item.prescription.text) + " ")]) : _vm._e()])]), _c('li', [_c('p', [_vm._v("效期")]), item.nearEffectivePeriod ? _c('p', [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.nearEffectivePeriod)) + " ")]) : _c('p')]), Number(item.isMediumPackage) === 1 ? _c('li', [_c('p', [_vm._v("中包装数量")]), _c('p', [_vm._v(_vm._s(item.mediumPackage))])]) : _vm._e(), _vm.isCurrentLogin && !_vm.isExternal && item.isFactoryAndBelongProductFactory == 0 ? _c('li', [_c('p', [_vm._v("库存")]), _c('p', [_vm._v(_vm._s(item.availableNum > 99 ? "99+" : item.availableNum))])]) : _vm._e(), _vm.isCurrentLogin && !_vm.isExternal && item.isFactoryAndBelongProductFactory == 1 ? _c('li', [_c('p', [_vm._v("库存")]), _c('p', [_vm._v(_vm._s(item.availableNum))])]) : _vm._e()])])])];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "list-dl",
      on: {
        "click": function click($event) {
          return _vm.toDetails(item);
        }
      }
    }, [_c('div', {
      staticClass: "list-dt"
    }, [_c('img', {
      attrs: {
        "src": item.mainImageUrl,
        "alt": ""
      }
    }), item.productAttribute == '1' ? _c('div', {
      staticClass: "product-hbimg-jf"
    }, [_vm._v(" 返利 ")]) : item.activityVOList[0] && item.activityVOList[0].isIntegralCommodity && item.activityVOList[0].isIntegralCommodity == '1' ? _c('div', {
      staticClass: "product-hbimg"
    }, [_c('img', {
      attrs: {
        "src": require("@images/2.gif")
      }
    })]) : _vm._e(), item.hospitalClassificationValue && item.hospitalClassificationValue == '1' ? _c('div', {
      staticClass: "hospital"
    }, [_vm._v(" 医院 ")]) : _vm._e(), item.productPatent && item.productPatent == '1' ? _c('img', {
      staticClass: "db_icon",
      attrs: {
        "src": "https://obs.pujian.com/frontend/app/components/db_icon.png",
        "alt": ""
      }
    }) : _vm._e(), item.isZeroPriceDifference == 1 ? _c('div', {
      staticClass: "zero-price-img"
    }, [_c('img', {
      style: {
        width: item.orderTime ? '29px' : '39px',
        height: item.orderTime ? '33px' : '43px'
      },
      attrs: {
        "src": require("@images/PC0.png")
      }
    })]) : _vm._e(), item.topType ? _c('p', {
      staticClass: "top-box"
    }, [_vm._v("TOP " + _vm._s(item.topType))]) : _vm._e(), item.buycs && item.buycs > 0 ? _c('p', {
      staticClass: "top-box left-box"
    }, [_vm._v("买过" + _vm._s(item.buycs) + "次")]) : _vm._e(), item.orderTime ? _c('p', {
      staticClass: "sale-count"
    }, [_vm._v(_vm._s(_vm._f("timeFilter")(item.orderTime)))]) : _vm._e(), Number(item.availableNum) === 0 ? _c('div', {
      staticClass: "bg-sold",
      staticStyle: {
        "flex-flow": "wrap",
        "flex-direction": "column"
      }
    }, [_c('span', {
      staticStyle: {
        "width": "100%"
      }
    }, [_vm._v("已售罄")]), item.arrivalTime && item.arrivalTime != '' ? _c('div', [_vm._v("预计到货时间")]) : _vm._e(), item.arrivalTime && item.arrivalTime != '' ? _c('div', [_vm._v(_vm._s(item.arrivalTime))]) : _vm._e()]) : Number(item.totalNumber) > Number(item.availableNum) && item.preferenticePrice !== null ? _c('div', {
      staticClass: "bg-sold"
    }, [_c('span', [_vm._v("库存不足")])]) : _vm._e(), item.preferenticePrice !== null && (item.availableNum === '0' ? '' : !item.isInvalid) ? _c('p', {
      staticClass: "bg-sold"
    }, [_c('span', [_vm._v("失效")])]) : _vm._e()]), _c('div', {
      staticClass: "list-dd"
    }, [_c('div', {
      staticClass: "md-title"
    }, [item.category && item.category == '1' ? _c('span', {
      staticClass: "RX-red"
    }, [_vm._v("甲")]) : _vm._e(), item.category && item.category == '2' ? _c('span', {
      staticClass: "RX-red"
    }, [_vm._v("乙")]) : _vm._e(), item.category && item.category == '3' ? _c('span', {
      staticClass: "RX-red"
    }, [_vm._v("丙")]) : _vm._e(), _c('span', {
      staticClass: "name"
    }, [_vm._v("【" + _vm._s(item.specifications) + "】" + _vm._s(item.productName))])]), _vm._l(item.activityVOList, function (activity, acIndex) {
      return item.activityVOList.length > 0 && _vm.isOtherAccount == true ? _c('div', {
        key: acIndex,
        staticStyle: {
          "display": "flex"
        }
      }, [activity.type == 1 ? _c('ActivityLine', {
        attrs: {
          "activeName": "满减",
          "type": activity.type,
          "bgColor": "#FFF3F3",
          "borderColor": "#FFF3F3",
          "color": "#000000",
          "isDetail": true,
          "activeContent": activity.activityName
        }
      }) : _vm._e(), activity.type == 2 || activity.type == 4 ? _c('ActivityLine', {
        attrs: {
          "activeName": "返红包",
          "type": activity.type,
          "bgColor": "#FFF5D6",
          "borderColor": "#FFF5D6",
          "color": "#000000",
          "isDetail": true,
          "activeContent": activity.activityName
        }
      }) : _vm._e()], 1) : _vm._e();
    }), _c('div', {
      staticClass: "md-describe"
    }, [_vm._v(_vm._s(_vm._f("ellipsis")(item.produceFactory)))]), _c('div', {
      staticClass: "md-time"
    }, [item.nearEffectivePeriod ? _c('p', [_c('span', [_vm._v("效期")]), _vm._v(" " + _vm._s(_vm._f("formatDate")(item.nearEffectivePeriod)) + " ")]) : _c('p', [_c('span', [_vm._v("效期")])])]), _c('div', {
      staticClass: "md-time"
    }, [item.lastReturnTime ? _c('p', [_c('span', [_vm._v("最后退货时间")]), _vm._v(" " + _vm._s(item.lastReturnTime) + " ")]) : _vm._e()])], 2)])]), _c('div', {
      staticClass: "md-money"
    }, [_c('div', [_vm.isExternal ? _c('span', [_vm._v("价格不可见")]) : item.preferenticePrice !== null ? [!item.superDiscountProductPrice ? _c('span', [_c('span', {
      staticStyle: {
        "font-size": "16px"
      }
    }, [_vm._v("¥")]), _vm._v(_vm._s(item.preferenticePrice)), item.sellUnit ? _c('span', [_vm._v("/" + _vm._s(item.sellUnit))]) : _vm._e()]) : _vm._e(), item.superDiscountProductPrice ? _c('div', {
      staticClass: "SpecialPrice"
    }, [_c('span', [_vm._v("特价")]), _c('span', [_vm._v("￥" + _vm._s(item.superDiscountProductPrice))]), item.superDiscountProductNum ? _c('span', [_vm._v(_vm._s(item.superDiscountProductNum) + _vm._s(item.sellUnit) + "起购")]) : _vm._e()]) : _vm._e(), item.superDiscountProductPrice ? _c('div', {
      staticClass: "old_price"
    }, [_c('label', [_vm._v("¥")]), _vm._v(_vm._s(item.preferenticePrice)), item.sellUnit ? _c('label', [_vm._v("/" + _vm._s(item.sellUnit))]) : _vm._e()]) : _vm._e(), item.activityVOList && item.activityVOList.length > 0 ? _c('div', {
      staticClass: "md-money-activity"
    }, [_vm._v("活动价约¥" + _vm._s(item.activityVOList[0].activityZhPrice))]) : _vm._e(), item.suggestedPrice && Number(item.suggestedPrice) > 0 ? _c('div', {
      staticClass: "md-money-mll"
    }, [_vm._v(" 零售价：" + _vm._s(item.suggestedPrice)), item.sellUnit ? _c('font', [_vm._v(_vm._s('/' + item.sellUnit))]) : _vm._e(), _vm._v("  毛利率" + _vm._s(Number(item.grossProfitRate).toFixed(0)) + "% ")], 1) : _vm._e()] : _c('span', [_vm._v("价格登录后可见")])], 2)]), !_vm.isShow || _vm.isUserPoints === 1 || _vm.isUserPoints === 3 ? _c('div', {
      staticClass: "md-reference"
    }) : _vm._e(), _vm.isShow && _vm.isUserPoints === 2 ? _c('div', {
      staticClass: "md-group"
    }, [_c('p', [_vm._v("拼团还剩 "), _c('countdown', {
      attrs: {
        "value": item.groupEnd - item.nowTimestamp,
        "format": "dd天hh时mm分ss秒"
      },
      on: {
        "stop": _vm.countDownStop
      }
    }), _vm._v("结束")], 1), _c('p', [_vm._v("已拼团 "), _c('span', [_vm._v(_vm._s(item.alreadyCount))]), _vm._v("家客户 还差"), _c('span', [_vm._v(_vm._s(item.differenceCount))]), _vm._v("家拼成")])]) : _vm._e(), _vm.isShow && _vm.isUserPoints === 2 ? _c('div', {
      staticClass: "md-participation"
    }, [_c('b-button', {
      class: [{
        'bg-submit-button': item.totalNumber === 0 || item.availableNum === '0' || !item.isInvalid || item.isBtn || _vm.isExternal
      }, 'participation-btn'],
      staticStyle: {
        "width": "116px !important"
      },
      attrs: {
        "disabled": item.totalNumber === 0 || item.availableNum === '0' || !item.isInvalid || item.isBtn || _vm.isExternal
      },
      on: {
        "click": function click($event) {
          return _vm.toAddCart(item);
        }
      }
    }, [_vm._v(" 参与拼团 ")]), _c('p')], 1) : _vm._e(), !_vm.isShow || _vm.isUserPoints === 1 || _vm.isUserPoints === 3 ? _c('div', {
      staticClass: "md-num"
    }, [_c('div', {
      staticClass: "number-input"
    }, [Number(item.isMediumPackage) === 1 ? _c('div', {
      staticClass: "md-package"
    }, [_vm._v(" 中包装(1中包装=" + _vm._s(item.mediumPackage) + _vm._s(item.sellUnit) + ") ")]) : _vm._e(), _c('div', {
      staticClass: "input-box"
    }, [!item.isCheckMin ? _c('span', {
      staticClass: "minus",
      on: {
        "click": function click($event) {
          return _vm.handleMinus(item);
        }
      }
    }, [_vm._v("-")]) : _c('span', {
      staticClass: "bg-minus minus"
    }, [_vm._v("-")]), _c('div', {
      staticClass: "field"
    }, [_c('div', {
      staticClass: "control"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model.trim.number",
        value: item.totalNumber,
        expression: "item.totalNumber",
        modifiers: {
          "trim": true,
          "number": true
        }
      }],
      staticClass: "input",
      attrs: {
        "type": "number",
        "placeholder": "0",
        "maxlength": "4",
        "disabled": item.status == '4'
      },
      domProps: {
        "value": item.totalNumber
      },
      on: {
        "input": [function ($event) {
          if ($event.target.composing) return;
          _vm.$set(item, "totalNumber", _vm._n($event.target.value.trim()));
        }, function ($event) {
          return _vm.handleInput(item, item.productId);
        }],
        "blur": [_vm.handleBlur, function ($event) {
          return _vm.$forceUpdate();
        }]
      }
    })])]), item.isFlag ? _c('span', {
      staticClass: "sign",
      on: {
        "click": function click($event) {
          return _vm.handleSign(item);
        }
      }
    }, [_vm._v("+")]) : _c('span', {
      staticClass: "bg-minus sign"
    }, [_vm._v("+")])]), Number(item.isMediumPackage) === 1 ? _c('div', {
      staticClass: "md-count"
    }, [_vm._v(" 实际购买"), _c('span', [_vm._v(_vm._s(item.Package) + _vm._s(item.sellUnit))])]) : _vm._e(), item.limitSaleNumber != 0 || item.notLowerSale != 0 ? _c('div', {
      key: item.productId,
      staticClass: "messages"
    }, [item.isMin ? _c('p', {
      staticClass: "help is-danger"
    }, [_vm._v(" 您输入的购买数量小于最低数量要求！ ")]) : _vm._e(), item.isMax ? _c('p', {
      staticClass: "help is-danger"
    }, [_vm._v(" 您输入的购买数量大于最多数量限制！ ")]) : _vm._e()]) : _vm._e()])]) : _vm._e(), !_vm.isShow || _vm.isUserPoints === 1 || _vm.isUserPoints === 3 ? _c('div', {
      staticClass: "md-add-cart-wrap"
    }, [_vm.isUserPoints === 2 ? _c('b-button', {
      staticClass: "ponits-btn",
      attrs: {
        "disabled": !item.accord || item.availableNum === '0'
      },
      on: {
        "click": function click($event) {
          return _vm.getIntegral(item);
        }
      }
    }, [_vm._v("获取积分")]) : _vm._e(), _c('b-button', {
      class: [{
        'bg-submit-button': item.totalNumber === 0 || item.availableNum === '0' || !item.isInvalid || item.isBtn || _vm.isExternal
      }, 'submit-button'],
      staticStyle: {
        "width": "116px !important"
      },
      attrs: {
        "type": "is-info",
        "disabled": item.totalNumber === 0 || item.availableNum === '0' || !item.isInvalid || item.isBtn || _vm.isExternal
      },
      on: {
        "click": function click($event) {
          return _vm.toAddCart(item);
        }
      }
    }, [_vm._v("加入购物车")])], 1) : _vm._e()], 1)]);
  }), _vm.isShowMask ? _c('div', {
    staticClass: "mask"
  }, [_c('PointsAnimation', {
    attrs: {
      "pointsNum": _vm.coinsNum
    }
  })], 1) : _vm._e(), _vm.isSuccess ? _c('div', {
    staticClass: "success-bg"
  }, [_vm.nowItem.superDiscountProductPrice ? _c('div', {
    staticClass: "success"
  }, [_vm.totalNumber > _vm.nowItem.superDiscountProductNum ? _c('div', [_c('p', [_vm._v("前" + _vm._s(_vm.nowItem.superDiscountProductNum) + _vm._s(_vm.nowItem.sellUnit) + "  " + _vm._s(_vm.nowItem.superDiscountProductPrice) + "元" + _vm._s(_vm.nowItem.sellUnit))]), _c('p', [_vm._v("超过" + _vm._s(_vm.nowItem.superDiscountProductNum) + _vm._s(_vm.nowItem.sellUnit) + "  " + _vm._s(_vm.nowItem.preferenticePrice) + "元" + _vm._s(_vm.nowItem.sellUnit))])]) : _c('div', [_c('p', [_vm._v("特价商品")]), _c('p', [_vm._v("成功加入购物车")])])]) : _c('div', {
    staticClass: "success"
  }, [_vm._v("已加入购物车 "), _vm.isMediumPackage == 1 ? _c('p', [_vm._v("购买" + _vm._s(_vm.totalNumber) + "个中包装，实际购买" + _vm._s(_vm.Package) + _vm._s(_vm.sellUnit))]) : _vm._e()])]) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };