import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
export default {
  data: function data() {
    return {};
  },
  computed: {
    detailData: function detailData() {
      return this.$root.CurrentMonthStatistics;
    }
  },
  mounted: function mounted() {},
  methods: {
    formatNum: function formatNum(num) {
      if (num && Number(num) && Number(num) > 0) {
        return Number(num).toFixed(2);
      } else {
        return "0.00";
      }
    },
    gomyaccount: function gomyaccount() {
      this.$router.push({
        path: '/user-center/my-account'
      });
    }
  }
};