import "core-js/modules/es.number.constructor.js";
export default {
  name: "index",
  props: {
    bgColor: {
      type: String,
      default: '#EBF5FB'
    },
    color: {
      type: String,
      default: '#3875F6'
    },
    titleColor: {
      type: String,
      default: '#ffffff'
    },
    activeName: {
      type: String,
      default: ''
    },
    activeContent: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '40'
    },
    border: {
      type: Boolean,
      default: true
    },
    borderColor: {
      type: String,
      default: '#EBF5FB'
    },
    title: {
      type: Boolean,
      default: true
    },
    titleBg: {
      type: String,
      default: 'linear-gradient(180deg, #75CBFF 0%, #1C78E9 100%)'
    },
    type: {
      type: Number,
      default: 3
    },
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      type_1: "linear-gradient(180deg, #FFB4D0 0%, #FF3636 100%)",
      //满减
      type_2: "linear-gradient(180deg, #FFBA6B 0%, #FF5741 100%)",
      //返红包
      type_3: "linear-gradient(180deg, #FF9EFF 0%, #FF3487 100%)" //拼团
    };
  },
  computed: {
    bgStyle: function bgStyle() {
      var style = {};
      style.background = this.bgColor;
      style.color = this.color;
      style.height = this.height + 'rpx';
      style.borderColor = this.borderColor;
      if (!this.border) style.border = 'none';
      if (!this.title) style.paddingLeft = '12rpx';
      return style;
    }
  },
  filters: {
    replaceStr: function replaceStr(str) {
      var arr = str.split("-");
      return arr[0];
    },
    replaceStr1: function replaceStr1(str) {
      var arr = str.split("-");
      return arr[1];
    }
  }
};